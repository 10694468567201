/**
 * @function getFilteredPaymentMethodGroups remove blacklisted key on payment method groups
 * @param {paymentMethodGroups} Object payment method groups
 * @param {isPaymentTermOneTime} Boolean define if selected payment term equals ONE_TIME
 * @returns {Object} filtered payment method groups or empty object
 */
export const getFilteredPaymentMethodGroups = ({
  paymentMethodGroups,
  isPaymentTermOneTime,
}) => {
  const blacklistedPaymentMethodGroups = isPaymentTermOneTime
    ? []
    : ["Cicilan"];

  if (!Object.keys(paymentMethodGroups).length) {
    return {};
  }

  return Object.keys(paymentMethodGroups)
    .filter((key) => !blacklistedPaymentMethodGroups.includes(key))
    .sort()
    .reduce((obj, key) => {
      obj[key] = paymentMethodGroups[key];
      return obj;
    }, {});
};

export const getSalesforceFilteredPaymentMethodGroups = ({
  paymentMethodGroups,
}) => {
  if (!Object.keys(paymentMethodGroups).length) {
    return {};
  }

  return Object.keys(paymentMethodGroups)
    .filter((key) =>
      ["Transfer Bank", "Kartu Kredit / Kartu Debit"].includes(key)
    )
    .reduce((obj, key) => {
      obj[key] = {
        ...paymentMethodGroups[key],
        children: paymentMethodGroups[key].children.filter(
          (objKeyChild) => objKeyChild.paymentProvider === "XENDIT"
        ),
      };

      return obj;
    }, {});
};
