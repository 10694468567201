import { createActions } from '../../utils';

let resources = [
  'order',
  'outstanding payments',
  'transaction status',
  'checkout data',
  'order by quotation id',
  'latest payment',
  'quotation slip'
],
  otherActions = [
    'error loading order',
    'error loading outstanding payments',

    'pay outstanding transactions',
    'paying outstanding transactions',
    'paid outstanding transactions',
    'error paying outstanding transactions',

    'initiate payment',
    'initiating payment',
    'initiated payment',
    'error initiating payment',
    'reset initiate payment response',
    'reset payment error message',
    
    'initiate payment salesforce',
    'initiating payment salesforce',
    'initiated payment salesforce',
    'error initiating payment salesforce',

    'send policy',
    'sending policy',
    'sent policy',
    'error sending policy',

    'error loading transaction status',

    'error loading checkout data',
    'error loading order by quotation id',

    'add payment link voucher',
    'adding payment link voucher',
    'added payment link voucher',
    'error adding payment link voucher',

    'remove payment link voucher',
    'removing payment link voucher',
    'removed payment link voucher',
    'error removing payment link voucher',
    'error loading latest payment',

    'duplicate order',
    'duplicating order',
    'duplicated order',
    'error duplicating order',
    
    'error loading quotation slip',
  ];

export default createActions(resources, otherActions);
